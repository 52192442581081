<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">庆贺！厦门CA与历思科技正式建立战略合作伙伴关系！</div>
      <p class="time">2020.04.29</p>
      <p class="space_content" >
        2020年4月23日，厦门数字证书管理有限公司（以下简称“厦门CA”）与厦门历思科技服务有限公司（以下简称”历思”）签署协议，正式建立战略合作伙伴关系。
      </p>
      <p class="space_content" >
        厦门CA是厦门地区唯一一家从事数字证书的制作、颁发和管理的第三方电子认证服务机构，是经国家密码管理局（原国家密码管理委员会办公室）批准的数字证书服务机构，由厦门信息集团信息港建设发展股份有限公司与福建省电子信息集团下属福建省数字安全证书管理有限公司共同出资成立，是经厦门市政府及行业主管部门批准的从事电子认证信息安全服务的专业机构。
      </p>
      <p class="space_content" >
        厦门CA作为厦门市权威的、公正的第三方电子认证信息安全服务机构，为厦门市电子政务、电子商务等领域提供符合国际、国内信息安全协议标准的电子认证证书。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/28-1.png')">
      </div>

      <p class="space_content" >
        优势技术的综合性科技服务公司，其旗下福建历思司法鉴所历经十多年发展，规范经营与开拓创新并重，逐步建立起一个声誉良好的司法鉴定品牌。历思目前已拥有按国家实验室标准建设的3100多平方米的大型物证中心、执业项目齐全（目前拥有17大项司法鉴定执业资质，基本覆盖司法鉴定各个领域）的前沿型跨区域司法鉴定服务机构。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/28-2.png')">
      </div>
      <p class="space_content" >
        双方将强强联合共同利用各自在数字证书管理、物证与鉴定等方面的优势为社会提供全方位的电子化证据与物证电子化服务，通过业务合作与创新，加强抗风险能力，共同做大做强，实现跨越式发展。


      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/27'"><p class="ellipsis1">庆贺！福建历思司法鉴定所党支部与厦门市税务局第二稽查局检查三科党支部签订党建共建协议！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/29'"><p class="ellipsis1">母亲节福利来了！珠宝玉石免费检测鉴定活动——3天！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
